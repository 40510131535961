.pointer_form {
    position: relative;

    .form-label {
        margin-bottom: 5em;
    }
    
    .hidden_scan_input {
        opacity: 0;
        position: absolute;
        z-index: -1;
        width: 13em;
    }

    .spinner {
        opacity: 0;
    }

    .emergency_wrapper {
        position: absolute;
        top: 45%;
        text-align: center;
    }

    &:focus-within {
        .spinner {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            opacity: 1;
        }
        .emergency_focus {
           display: none;
        }
    }
}