.barcode-scanner {
    position: relative;

    & > video {
        position: relative;
        width: 100%;
    }

    .drawingBuffer {
        position: absolute;
        top: 0;
        left:0;
        max-width: 100%;
    }
}